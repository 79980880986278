import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  dev: {
    apiKey: "AIzaSyAZa1VkXoOR7WmHP7K0EYKGhMAJ-GaRUP4",
    authDomain: "ardarhayam.firebaseapp.com",
    projectId: "ardarhayam",
    storageBucket: "ardarhayam.appspot.com",
    messagingSenderId: "866225066149",
    appId: "1:866225066149:web:404fc61b1320c5144d3d4c",
    measurementId: "G-KDB8EDCLWN",
  },
  prod: {
    apiKey: "AIzaSyDORPtLgnt9NgrxN2hGc1-QLz4WDMmcA40",
    authDomain: "ardarhay-am.firebaseapp.com",
    projectId: "ardarhay-am",
    storageBucket: "ardarhay-am.firebasestorage.app",
    messagingSenderId: "1014762557896",
    appId: "1:1014762557896:web:51c0e01fab8cff2607f0fd",
  },
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig.prod);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
export const analytics = typeof window !== "undefined" ? getAnalytics(app) : null;
