import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPrograms,
  getProgramById,
  getProgramsByCategory,
} from "api/programs.api";

export const getProgramsAsync = createAsyncThunk(
  "programs/get-all",
  async () => {
    const programs = await getPrograms();
    return programs;
  }
);

export const getProgramByIdAsync = createAsyncThunk(
  "programs/get-by-id",
  async (id) => {
    const program = await getProgramById(id);
    let content = "";
    if (program.content) {
      const response = await fetch(program.content);
      const text = await response.text();
      content = response.status === 200 ? text : "";
    }
    return { ...program, content };
  }
);

export const getProgramsByCategoryAsync = createAsyncThunk(
  "programs/get-by-category",
  async (category) => {
    const programs = await getProgramsByCategory(category);
    return programs;
  }
);
