import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { IoMenu } from "react-icons/io5";
import Image from "next/image";
import { useRouter } from "next/router";
import { FaTelegramPlane } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { keyframes } from "@emotion/react";
import { styled } from "@mui/material/styles";
import logo from "../../public/logo.svg";
import Link from "next/link";

// Define the color animation using keyframes
const colorChange = keyframes`
  0% {
    color: #4267B2; /* Facebook blue */
  }
  33% {
    color: #1877F2; /* Lighter Facebook blue */
  }
  66% {
    color: #8b9dc3; /* Faded blue */
  }
  100% {
    color: #4267B2; /* Back to original Facebook blue */
  }
`;
// Styled component for Telegram Icon with hover animation
const HoverableTelegramIcon = styled(FaTelegramPlane)`
  font-size: 26px; /* Adjust size as needed */
  color: gray; /* Default color */
  cursor: pointer;
  &:hover {
    animation: ${colorChange} 2s infinite;
  }
`;
// Styled component for Facebook Icon with hover animation
const HoverableFacebookIcon = styled(FaFacebookSquare)`
  font-size: 26px; /* Adjust size as needed */
  color: gray; /* Default color */
  cursor: pointer;
  &:hover {
    animation: ${colorChange} 2s infinite;
  }
`;

const pages = [
  {
    title: "Հաղորդումներ",
    value: "programs",
    submenu: {
      all: "Բոլորը",
      veritas: "Ճշմարտություն",
      whatToDo: "Ի՞նչ Անել",
      revelation: "Բացահայտված է",
      fair: "Արդար Հայը",
      proverbial: "Առականի",
    },
  },
  { title: "Վերլուծություններ", value: "analyses" },
  { title: "Հատուկ Թողարկում", value: "specials" },
  { title: "Իրադարձություններ", value: "events" },
];

function ResponsiveAppBar() {
  const router = useRouter();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = React.useState(null);
  const [openSubmenuFor, setOpenSubmenuFor] = React.useState(null);
  const [mobileSubmenuOpen, setMobileSubmenuOpen] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    handleCloseSubmenu();
  };

  const handleOpenSubmenu = (event, pageValue) => {
    setSubmenuAnchorEl(event.currentTarget);
    setOpenSubmenuFor(pageValue);
  };

  const handleCloseSubmenu = () => {
    setSubmenuAnchorEl(null);
    setOpenSubmenuFor(null);
  };

  const handleMobileSubmenuToggle = (pageValue) => {
    setMobileSubmenuOpen((prev) => (prev === pageValue ? null : pageValue));
  };

  return (
    <AppBar
      sx={{ backgroundColor: "#fff" }}
      position="fixed"
      className="lg:px-20 xl:px-40 px-4"
      elevation={4}
    >
      <Toolbar>
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".6rem",
            color: "black",
            textDecoration: "none",
            fontSize: "30px",
          }}
        >
          <Image
            width={80}
            height={80}
            sizes="100vh"
            className="absolute top-0"
            src={logo}
            alt="logo"
            priority
          />
        </Typography>

        {/* Main Navigation */}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            marginLeft: "140px",
          }}
        >
          {pages.map((page) => (
            <React.Fragment key={page.value}>
              <Button
                className="animated-border uppercase"
                onClick={(event) =>
                  page.submenu
                    ? handleOpenSubmenu(event, page.value)
                    : router.push(`/${page.value}`)
                }
                sx={{
                  my: 2,
                  color:
                    router?.asPath === `/${page.value}` ? "#636363" : "black",
                  display: "block",
                  textTransform: "none",
                  fontSize: "14px",
                }}
              >
                {page.title}
              </Button>
              {page.submenu && (
                <Menu
                  anchorEl={submenuAnchorEl}
                  open={
                    Boolean(submenuAnchorEl) && openSubmenuFor === page.value
                  }
                  onClose={handleCloseSubmenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {Object.entries(page.submenu).map(([key, title]) => (
                    <MenuItem
                      key={key}
                      onClick={() => {
                        handleCloseNavMenu();
                        router.push(
                          key === "all"
                            ? `/${page.value}`
                            : `/${page.value}/category/${key}`
                        );
                      }}
                    >
                      <Typography>{title}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </React.Fragment>
          ))}
        </Box>

        {/* Mobile Menu */}
        <div className="absolute right-0 block md:hidden">
          <IconButton
            size="large"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="black"
          >
            <IoMenu />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <div key={page.value}>
                <MenuItem
                  onClick={() => {
                    page.submenu
                      ? handleMobileSubmenuToggle(page.value)
                      : router.push(`/${page.value}`);
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        router?.asPath === `/${page.value}`
                          ? "#636363"
                          : "black",
                    }}
                  >
                    {page.title}
                  </Typography>
                </MenuItem>
                {/* Submenu for Mobile */}
                {page.submenu && mobileSubmenuOpen === page.value && (
                  <Box sx={{ pl: 2 }}>
                    {Object.entries(page.submenu).map(([key, title]) => (
                      <MenuItem
                        key={key}
                        onClick={() => {
                          handleCloseNavMenu();
                          router.push(
                            key === "all"
                              ? `/${page.value}`
                              : `/${page.value}/category/${key}`
                          );
                        }}
                      >
                        <Typography sx={{ color: "black" }}>{title}</Typography>
                      </MenuItem>
                    ))}
                  </Box>
                )}
              </div>
            ))}
          </Menu>
        </div>
        <div className="flex items-center justify-center gap-2 hidden lg:flex">
          <Link
            target="_blank"
            href="https://www.facebook.com/profile.php?id=61567661321443"
          >
            <HoverableFacebookIcon />
          </Link>
          <Link
            target="_blank"
            href="https://t.me/ardarhay"
          >
            <HoverableTelegramIcon />
          </Link>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default ResponsiveAppBar;
