import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  limit,
  updateDoc,
  increment,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "config/firebase";

const getDocument = (id) => {
  return doc(db, "articles", id);
};

export const getArticles = async () => {
  const q = query(
    collection(db, "articles"),
    limit(10),
    orderBy("createdAt", "desc"),
    // where("published", "==", true)
  );
  const querySnapshot = await getDocs(q);
  const articles = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  return articles;
};

export const getArticleById = async (id) => {
  const docRef = getDocument(id);
  const docSnap = await getDoc(docRef);
  const article = docSnap.data();
  if (docSnap.exists) {
    updateDoc(getDocument(id), { views: increment(1) });
    return { ...article, id: docSnap.id };
  }
};

export const getArticlesByCategory = async (category) => {
  try {
    const q = query(
      collection(db, "articles"),
      where("categories", "array-contains", category),
      orderBy("createdAt", "desc"),
      // where("published", "==", true)
    );
    const docSnapshots = await getDocs(q);

    const articles = docSnapshots.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });

    return articles;
  } catch (error) {
    console.error(`Error while getting articles by category ${error}`);
    return error;
  }
};
